<template>
  <div class="container">
    <h1 id="title">COFFEE</h1>
    <v-divider color="black"></v-divider>
    <div class="menu">
      <div v-for="item in items" :key="item">
        <v-row class="item">
          <v-col cols="9">
            <div class="item_type">{{item.title}}<span style="font-size: 13px; font-weight: 400; margin-left: 3px;">{{item.mini_word}}</span></div>
            <div class="desc">{{item.description}}</div>
          </v-col>
          <v-col cols="3">
            <div class="price-holder">
              <div class="price">{{item.price}}</div>
              <div class="about_price">{{item.about_price}}</div>
              <div class="cup">{{item.cup}}</div>
              <div class="beans">{{item.beans}}</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="statement text-right">
      <div class="text_1">* マイボトル持参の場合 -20yen</div>
    </div>
  </div>
</template>

<script>
  import coffee from '../assets/coffee-data.json'
  export default {
    name: 'Coffee',
    data () {
      return {
        items: coffee,
      }
    }
  }
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

#title {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 25px;
  margin: 33px 0px 7px 0px;
  text-align: center;
}
.menu {
  margin-top: 30px;
  margin-left: 30px;
}
.item {
  margin-bottom: 26px; 
}

.item_type {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight:600;
  padding-bottom: 7px;
}

.sub_title {
  font-weight: 500;
  font-size:11px;
}

.sub_item_type {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 12px;
}
.name_en {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 11px;
}
.desc {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 11px;
  font-weight: 600;
}

.price-holder {
  text-align: right;
  margin-right: 10px;
}

.price {
  font-size: 10px;
  text-align: right;
  margin-right: 9.5px;
}

.about_price {
  font-size: 10px;
  text-align: right;
}

.cup {
  font-size: 10px;
  text-align: right;
  white-space: pre-wrap;
}

.beans {
  font-size: 10px;
  text-align: right;
  white-space: pre-wrap;
}

.statement {
  margin: 50px 25px 10px 20px;
  height: 40px;
  font-size: 8px;
  text-align: right;
}

.text_1 {
  margin-right: 5px;
}
</style>